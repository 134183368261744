<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.solution_list')}}</h2>
    </div>

    <el-button type="primary" size="mini" @click="createDialogVisible = true">{{$t('button.add')}}</el-button>
    <el-button type="danger" :disabled="disabled" size="mini" @click="removeSolution(deleteCategory)">{{$t('button.del')}}</el-button>

    <el-form :inline="true" class="mt20">
      <el-form-item :label="$t('page.product.name')">
        <el-input v-model="query" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" size="mini">{{$t('search')}}</el-button>
      </el-form-item>
    </el-form>

    <el-table
        class="mt20"
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        size="small"
        @selection-change="solutionChange"
        tooltip-effect="dark">
      <el-table-column
          type="selection">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          :prop="isEn + `title`"
          :label="$t('form.title')">
      </el-table-column>
      <el-table-column
          :prop="isEn + `desc`"
          :label="$t('form.desc')">
      </el-table-column>
      <!--<el-table-column
          label="状态">
        <template v-slot="scope">
          <el-switch
              v-model="scope.row.status">
          </el-switch>
        </template>
      </el-table-column>-->
      <!--<el-table-column
          prop="username"
          label="创建用户">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="update_time"
          label="修改时间"
          show-overflow-tooltip>
      </el-table-column>-->
      <el-table-column
          width="200"
          :label="$t('action')">
        <template v-slot="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="getSolutionRow(scope.row)">{{$t('button.edit')}}</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeSolution(scope.row.id)">{{$t('button.del')}}</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog :visible.sync="imageSelectDialogVisible" title="选择图片">
      <select-image
          ref="selectImage"
          :key="multiple"
          :multiple="multiple"
          :active="false"
          @select="getImages"
          @close="imageSelectDialogVisible = false">
      </select-image>
    </el-dialog>

    <el-dialog width="70%" :visible.sync="createDialogVisible" :title="form.id ? $t('button.edit') : $t('button.add')">
      <el-form
          ref="addActivityRef"
          :rules="createRules"
          :model="form">
        <el-form-item :label="$t('form.title')">
          <el-input v-model="form.title" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="banner">
          <el-popover
              placement="top-start"
              width="200"
              trigger="hover">
            <el-image v-if="form.banner" :src="form.banner"></el-image>
            <el-input slot="reference" v-model="form.banner" size="small" clearable>
              <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('banner')"></el-button>
            </el-input>
          </el-popover>
        </el-form-item>
        <el-form-item :label="$t('form.title')">
          <el-input v-model="form.desc" type="textarea" :autosize="{ minRows: 4, maxRows: 6}" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('content')">
          <el-table :data="form.content" border>
            <el-table-column type="index" label="#"></el-table-column>

            <el-table-column prop="date" :label="$t('form.title')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.title" size="small" clearable></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="desc" :label="$t('form.desc')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.desc" size="small" clearable></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="url" :label="$t('url')">
              <template slot-scope="scope">
                <el-input slot="reference" v-model="scope.row.url" size="small" clearable>
                  <el-button slot="append" icon="el-icon-zoom-in" @click="selectView('url', scope.$index)"></el-button>
                </el-input>
              </template>
            </el-table-column>

            <el-table-column prop="icon" :label="$t('icon')">
              <template slot-scope="scope">
                <el-popover
                    placement="top-start"
                    width="200"
                    trigger="hover">
                  <el-image v-if="scope.row.icon" :src="scope.row.icon"></el-image>
                  <el-input slot="reference" v-model="scope.row.icon" size="small" clearable>
                    <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload('icon', scope.$index)"></el-button>
                  </el-input>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column :label="$t('image')">
              <template slot-scope="scope">
                <el-button type="primary" plain size="mini" icon="el-icon-picture-outline" @click="addImages('images', scope.$index)">{{scope.row.images.length}}</el-button>
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('action')">
              <template v-slot="scope">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeList(scope.$index)">{{$t('button.del')}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button class="mt20" type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs">{{$t('button.add')}}</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
            <el-button size="mini" type="primary" @click="onSave">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="imagesDialogVisible" :label="$t('image')">
      <el-row class="images-preview" :gutter="20" type="flex" justify="start">
        <el-col :span="6" v-for="(url, index) in images" :key="'images-preview-'+index">
          <div class="images-preview-center">
            <el-image
                fit="scale-down"
                lazy
                :src="url"
                :preview-src-list="images">
            </el-image>
            <div class="images-preview-mask">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteImage(index)"></el-button>
            </div>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="images-upload" @click="mediaUpload('images', index)">
            <i class="el-icon-plus"></i>
          </div>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button type="primary" size="mini" @click="imagesDialogVisible = false">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="selectPostDialogVisible" :title="$t('tips.select')">
      <select-post @select="select"></select-post>
    </el-dialog>
  </el-card>
</template>

<script>
import selectImage from '@/components/Images';
import selectPost from '@/view/os/console/SelectPost';
import {createSolution, getSolution, updateSolution, removeSolution} from "@/api/os/admin";

export default {
  data() {
    return {
      imageSelectDialogVisible: false,
      list: [],
      index: 0,

      multiple: false,
      createDialogVisible: false,
      form: {
        title: '',
        content: []
      },
      imagesDialogVisible: false,
      images: '',
      currentPage: 0,
      pageSize: 20,
      loading: false,
      // 删除数据
      deleteCategory: '',
      disabled: true,
      selected: 0,

      query: '',
      selectPostDialogVisible: false,
      selectMeta: {
        name: '',
        index: 0
      },
    }
  },
  components: {
    selectImage,
    selectPost
  },
  created() {
    this.get()
  },
  computed: {
    // add
    createRules() {

    },
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
  },
  methods: {
    selectView(name, index) {
      this.selectMeta.name = name
      this.selectMeta.index = index
      this.selectPostDialogVisible = true
    },
    select(data) {
      let url = data.url
      this.$set(this.form.content[this.selectMeta.index], this.selectMeta.name, url);
      this.$set(this.form.content[this.selectMeta.index], 'id', data.id);
      this.$set(this.form.content[this.selectMeta.index], 'title', data.title);
      this.$set(this.form.content[this.selectMeta.index], 'selectType', data.selectType);
      this.selectPostDialogVisible = false
    },
    addAttrs() {
      this.form.content.push({
        title: '',
        desc: '',
        icon: '',
        images: [],
        url: ''
      })
    },
    mediaUpload(name, index = 0) {
      this.imageSelectDialogVisible = true
      let state = false
      if(name == "images") state = true
      this.multiple = state;
      this.index = index
      this.name = name
    },
    getImages(data) {
      if(this.name == 'images') {
        let list = this.form.content[this.index][this.name].concat(data);
        this.form.content[this.index][this.name] = list
        this.images = list
      } else if(this.name == 'banner') {
        this.form.banner = data
      } else {
        this.form.content[this.index][this.name] = data
      }

      this.imageSelectDialogVisible = false
    },
    get() {
      getSolution({
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.query,
      }).then(res => {
        this.list = res.data.data.list
      })
    },
    onSave() {
      if(this.form.id) {
        updateSolution(this.form).then(res => {
          this.createDialogVisible = false
          this.$message.success(this.$t('tips.success'))
          this.get()
        })
      } else {
        createSolution(this.form).then(res => {
          this.createDialogVisible = false
          this.$message.success(this.$t('tips.success'))
          this.get()
        })
      }
    },
    deleteImage(index) {
      this.form.content[this.index].images.splice(index, 1)
    },
    addImages(name, index) {
      this.index = index
      this.name = name
      this.imagesDialogVisible = true
      this.images = this.form.content[index][name]
    },
    getSolutionRow(data) {
      this.createDialogVisible = true
      this.form = data
    },
    removeList(index) {
      this.form.content.splice(index, 1)
    },
    solutionChange(selection) {
      let data = []
      selection.forEach((v, i) => {
        data.push(v.id)
      })
      this.selected = data.length
      this.deleteCategory = data.join(',')

      if(this.deleteCategory) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 删除选中的id
    removeSolution(id) {
      if(!id) {
        this.$message.error(this.$t('tips.not_selected'))
        return
      }
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeSolution(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.get()
          }
        })
      })
    },
    onSearch() {
      this.currentPage = 0
      this.get()
    }
  }
}
</script>

<style lang="less" scoped>
.images-upload{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 128px;
  cursor: pointer;
  line-height: 126px;
  vertical-align: top;
  text-align: center;
  i{
    font-size: 28px;
    color: #8c939d;
  }
}
.images-preview{
  flex-wrap: wrap;
  flex-direction: row;
  .el-col{
    margin-bottom: 20px;
  }
  .images-preview-center{
    position: relative;
    height: 100%;
    &:hover{
      .images-preview-mask {
        display: block;
      }
    }
    .el-image{
      width: 100%;
      //height: 100px;
      display: block;
      cursor: pointer;
    }
    .images-preview-mask{
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,.6);
      display: none;
      text-align: center;
      .el-button{
      }
    }
  }
}
</style>